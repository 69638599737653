.v_container {
  margin-top: 10rem;
  margin-bottom: 10rem;
  padding: 6rem;
  display: flex;
  justify-content: space-around;
}
.left_card {
  display: flex;

  flex-direction: column;
  gap: 2rem;
  position: relative;
}
.left_card > :first-child {
  font-size: 2rem;
  text-transform: uppercase;
  display: block;
  font-weight: bold;
}
.left_card > :nth-child(2) {
  font-size: 1.6rem;
  text-transform: uppercase;
  display: block;
  width: 20rem;
}
.left_card > :nth-child(3) {
  font-size: 1.6rem;
  font-weight: bold;
}
.left_card > img {
  position: absolute;
  width: 10rem;
  bottom: 3rem;
}
.right_card {
  width: 30rem;
}
