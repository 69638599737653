.testimonials {
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
}
.wrapper {
  display: grid;
  align-items: flex-end;
  width: 100%;
  grid-template-columns: 1fr 2fr 1fr;
}
.wrapper > img {
  max-width: 100%;
  width: 25rem;
  justify-self: center;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.container > :first-child {
  font-size: 2rem;
  text-transform: uppercase;
  display: block;
  font-weight: bold;
}
.container > :nth-child(2) {
  font-size: 0.8rem;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
}
.wrapper > :nth-child(3) {
  text-align: right;
}
.reviews {
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 2rem;
}
.carousel {
  max-width: 100%;
}
.tCarousel {
  padding: 2rem;
  margin-top: 2rem;
}
.testimonial {
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fff;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  margin-top: 30px;
  box-shadow: 0rem 1rem 3rem -50rem #7d7d7d;
}
.testimonial > img {
  position: absolute !important;
  width: 3rem !important;
  top: -2rem !important;
  border-radius: 50%;
  border: 1px solid white !important;
  left: 45%;
}
.testimonial > span:nth-of-type(1) {
  align-items: center;
  font-size: 0.8rem;
  letter-spacing: 1px;
  margin-top: 2rem;
}
.testimonial > hr {
  height: 1px;
  width: 80%;
  background: rgba(198, 198, 198);
  border: none;
}
.testimonial > span:nth-of-type(2) {
  font-weight: 500;
}
