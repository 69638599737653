.footerWrapper {
  width: 100%;
  margin-top: 4rem;
}
.footerWrapper > hr {
  width: 100%;
  height: 1px;
  border: none;
  background: white;
  margin-top: 1rem;
}
.footer {
  margin-bottom: 4rem;
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.logo {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}
.logo > img {
  width: 2.5rem;
  height: 2.5rem;
}
.logo > span {
  font-weight: 600;
  font-size: 1rem;
}
.details {
  display: flex;
  flex-direction: column;
  width: inherit;
  font-size: 0.7rem;
  gap: 1rem;
  font-style: italic;
}
.details > span:nth-of-type(1) {
  font-weight: 500;
  font-style: normal;
  font-size: 1.2rem;
}
.pngLine:hover {
  cursor: pointer;
}
.pngLine {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.icon {
  width: 1.2rem;
  height: 1.2rem;
  padding-left: 0;
}
.copyRight {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  font-size: 0.65rem;
  margin-top: 2rem;
}
